
a:hover {
    cursor: pointer;
}

.inline-button {
    position: absolute;
    top: 8px;
    right: 5px;
    transition: right 0.2s;
    width: 50px;
}

.search-inline-button {
    position: absolute;
    top: 10px;
    right: 15px;
    transition: right 0.2s;
}

.inline-button:hover {
    cursor: pointer;
}

.search-menu {
    position: absolute;
    transform: translate3d(0px, 55px, 0px) !important;
    top: 10px;
    right: 0px !important;
    left: unset !important;
    will-change: transform;
    min-width: 500px;
    width: 35vw;
}

.color-light-grey {
    color: lightgray;
}

.color-dark-blue, .text-primary {
    color: var(--dark-blue) !important;
}

.panel-content {
    margin: 15px;
}

.checkbox-panel-content .row {
    margin-bottom: 15px;
}

.no-border {
    border: 0 !important;
}

.nav-menu li a {
    display: flex;
    align-items: center;
    outline: 0;
    padding: 0.8125rem 2rem;
    font-size: 0.875rem;
    color: #bdafd1 !important;
    font-weight: 400;
    text-decoration: none;
    position: relative;
}

.nav-menu li > ul li a {
    color: #af9fc7 !important;
    padding: 0.8125rem 2rem 0.8125rem 4rem !important;
}

.dropdown-menu.show {
    transition: .5s;
    -webkit-animation: animateFadeInUp .5s;
    -webkit-animation-name: bounce;
    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: 10;
    -webkit-animation-direction: alternate;
}

.local777TextA {
    color: #641A29 !important;
}

    .local777TextA:hover {
        cursor: pointer !important;
    }

.nav-menu li span {
    display: flex;
    align-items: center;
    outline: 0;
    padding: 0.8125rem 2rem;
    font-size: 0.875rem;
    color: #fff !important;
    font-weight: 400;
    text-decoration: none;
    position: relative;
}

.nav-menu li span > [class*='fa-'], .nav-menu li span > .ni {
    margin-right: 0.25rem;
    font-size: 1.125rem;
    width: 1.75rem;
    color: #876fab;
    text-align: left;
}

    .nav-menu li span:hover, .nav-menu li.active > span:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

.nav-menu li.active > span {
    color: white;
    background-color: rgba(255, 255, 255, 0.04);
    box-shadow: inset 3px 0 0 #dd5293;
    font-weight: 400;
    color: #fff !important;
    padding-left: 75px;
}

.nav-footer .nav-footer-buttons > li > span {
    display: block;
    color: #9782b7;
    height: 1.8125rem;
    line-height: 1.8125rem;
    margin-top: 1px;
    padding: 0 13px;
    overflow: visible;
    font-size: 1rem;
}

.nav-menu li.active:not(.open) {
    background: #24b3a4;
}

.nav-menu li.active:not(.open) > span:before {
    content: '\f413';
    font-family: 'nextgen-icons';
    position: absolute;
    top: calc(50% - 5px);
    right: 11px;
    font-size: 7px;
    height: 10px;
    width: auto;
    color: #24b3a4;
    display: flex;
    align-content: center;
    align-items: center;
}

.nav-menu li span.collapsed-hidden:hover, .nav-menu li.active > span.collapsed-hidden:hover,
.nav-menu li span.collapsed-reveal:hover, .nav-menu li.active > span.collapsed-reveal:hover,
.nav-menu li span.ml-auto:hover{
    background: unset;
}

.nav-menu li span .ml-auto,
.nav-menu li span .collapsed-hidden,
.nav-menu li span .collapsed-reveal {
    padding: 0;
}

    .nav-menu li span .collapsed-hidden i,
    .nav-menu li span .collapsed-reveal i {
        text-align: right;
        margin-right: 0;
    }

.nav-menu li > span {
    padding-left: 75px;
    color: #bdafd1 !important;
}

input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    z-index: 1;
}

.family-history-content .row, .personal-history-content .row,
.family-history-content .row, .personal-history-content .row {
    margin-bottom: 15px;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #d32979;
    background-color: #d32979;
}



.translateLeftNav {
    -webkit-transform: translate3d(0,0,0) !important;
    transform: translate3d(0,0,0) !important;
}

.checkbox-margin {
    margin-top: 30px;
}

@media only screen and (max-width: 992px) {
    .page-content-overlay.yes {
        background: rgba(0,0,0,.09);
    }

     .page-content-overlay.yes {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,.09);
    }

    .page-content-overlay {
        background: 0 0;
        -webkit-transition: background .3s;
        transition: background .3s;
        position: fixed;
        z-index: 1001;
    }

    .checkbox-margin {
        margin-top: unset;
    }
}



.breadcrumb {
    background: unset;
}

.modal-transparent .modal-content {
    background: rgba(73,26,48,.85);
}

.modal-transparent .modal-content {
    -webkit-box-shadow: 0 1px 15px 1px rgba(168,33,96,.3);
    box-shadow: 0 1px 15px 1px rgba(168,33,96,.3);
}

.leftNav-tinyLogo:hover, .page-logo:hover {
    cursor: pointer;
}

.info-card {
    background-image: linear-gradient(transparent, pink 5%, transparent 100%);
    padding: 0;
}

.Toastify__toast--success {
    background: #008900 !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

.toast {
    position: fixed !important;
    top: 60px !important;
    right: 0px !important;
    width: 250px;
    margin: 15px;
    font-size: 12px !important;
    z-index: 1;
}

    .toast.show {
        z-index: 1;
    }

.checkmark {
    font-size: 20px;
    color: green;
    align-content: center;
    align-self: center;
    height: 100%;
}

.cancel {
    font-size: 20px;
    color: red;
    align-content: center;
    align-self: center;
    height: 100%;
}

.slide-pane__overlay {
    z-index: 11111;
}

.modal {
    z-index: 99999;
}

.btn-submit {
    color: #fff;
    background-color: #641A29;
    border-color: #641A29;
}

    .btn-submit:hover {
        color: #fff;
        background-color: #AA1429;
        border-color: #AA1429;
    }

    .btn-submit:focus, .btn-submit.focus {
        color: #fff;
        background-color: #AA1429;
        border-color: #AA1429;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
    }

    .btn-submit.disabled, .btn-submit:disabled {
        color: #fff;
        background-color: #1dc9b7;
        border-color: #1dc9b7;
    }

    .btn-submit:not(:disabled):not(.disabled):active, .btn-submit:not(:disabled):not(.disabled).active,
    .show > .btn-submit.dropdown-toggle {
        color: #fff;
        background-color: #179c8e;
        border-color: #159184;
    }

        .btn-submit:not(:disabled):not(.disabled):active:focus, .btn-submit:not(:disabled):not(.disabled).active:focus,
        .show > .btn-submit.dropdown-toggle:focus {
            -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
        }

.rw-btn-select {
    display: none;
}

.rw-multiselect .rw-input-reset {
    height: unset !important;
    width: unset !important;
    padding: unset !important;
}


.rw-widget-input, .rw-filter-input {
    box-shadow: unset !important;
}

/*.rw-list-option.rw-state-focus{
    background: unset !important;
    color: unset !important;
    border: unset !important;
}*/

.rw-list-option.rw-state-focus:hover {
    background-color: #2196F3 !important;
    color: white !important;
    border: 2px solid black !important;
}

    .rw-list-option:hover, .rw-list-option:hover.rw-state-focus {
        background-color: #2196F3 !important;
        color: white !important;
        border: 2px solid black !important;
    }

.rw-multiselect-tag {
    margin-top: unset !important;
    margin-left: unset !important;
    margin-right: calc(0.279335em - 1px);
}

.rw-list-empty, .rw-list-option, .rw-list-optgroup {
    min-height: 30px;
}

.slide-pane-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.25rem;
    height: 90%;
    overflow-y: auto;
    line-height: 2rem;
}

.panel-hdr h3 {
    color: #641A29 !important;
    font-size: large;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-style: double;
}

.panel-hdr.noTitle {
    display: inline-block !important;
    float: right !important
}

.rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover {
    background-color: #337ab7;
    border-color: #337ab7;
    color: white;
}

.am-message-container {
    z-index: 111111 !important;
}

#am-confirm-container > div {
    z-index: 111111 !important;
}

.current-user {
    color: white;
    padding: 0.8125rem 2rem 0.8125rem 4rem !important;
}

.fa-2x {
    font-size: 2em !important;
}

.ant-select-dropdown {
    z-index: 111111;
}

.a {
    color: #1890ff;
}

.float-right {
    float: right;
}

.form-control-custom:disabled, .form-control-custom[readonly] {
    background-color: #f3f3f3;
    opacity: 1;
}

.ant-message {
    z-index: 999999 !important;
}

.refresh-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #fff;
    top: 0;
    opacity: .7;
}

.local777TextA {
    color: #275282 !important;
}

.local777TextA:hover {
    cursor: pointer !important;
}