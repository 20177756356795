@import url(theme.css);

.btn:hover {
    color: var(--royal-blue) !important;
    text-decoration: none;
    background-color: #fff !important;
}

.btn-info:hover {
    color: #fff !important;
    background-color: #138496 !important;
    border-color: #117a8b !important;
}

.btn-default {
    background-color: var(--royal-blue);
    background-image: -webkit-gradient(linear, left bottom, left top, from(#f5f5f5), to(#f1f1f1));
    background-image: linear-gradient(to top, #f5f5f5, #f1f1f1);
    color: #444;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

    .btn-default:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid var(--royal-blue);
        color: #333;
        z-index: 2;
    }

    .btn-default:focus {
        border-color: green !important;
        z-index: 3;
    }

.btn-outline-primary {
    border-color: var(--royal-blue) !important;
    color: #000 !important;
}

    .btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
        color: #000;
        background-color: var(--royal-blue);
        border-color: var(--royal-blue);
    }

    .btn-outline-primary:focus, .btn-outline-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(111, 178, 70, .5);
    }

.active.btn-default {
    background: #a38cc6;
    color: #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset !important;
}

.btn-submit {
    color: #fff;
    background-color: var(--royal-blue) !important;
    border-color: var(--dark-blue) !important;
}
