:root {
    --royal-blue: rgb(14,66,141); /*#0E428D*/
    --bg-color: #fff;
    --nav-text: #fff;
    --dark-blue: rgb(14,66,141); /*#3E4C8B*/
}

[data-theme="dark"] {
    --bg-color: #292929;
}
